import React from "react"
import logo from "../../assets/images/IBR_logo_web_de.png"
import {siteMetadata} from "../../../gatsby-config";
import {chakra,useColorModeValue} from "@chakra-ui/react"
export default function Logo(){
  
  return (
    <>
    <chakra.a href="#" fontSize={["xl", , "2xl"]} fontWeight="bold" color={useColorModeValue("gray.800", "white")} _hover={{ color: useColorModeValue("gray.700", "gray.300"),}}>
      <img className="h-36" alt={siteMetadata.title} src={logo} />
    </chakra.a>
    </>     
  )

}
