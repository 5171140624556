import React from "react";
import {Grid, GridItem,Box,Text,chakra} from "@chakra-ui/react"
import Logo from "../ui/logo";
import Contact from "../ui/contact";
import Question from "../ui/question";
function Footer () {
  return(
    
    <Grid as="footer" bgColor="blue.700" templateColumns={{sm:"1fr" ,  xl: "1fr 1fr 1fr 1fr"}} rounded="lg" shadow={"dark-lg"} gap={2} m={3} pb={4} pl={4} color="#CCCCCC">
      <GridItem justifySelf={{sm:"center", xl:"start"}} >
       <Contact/>
      </GridItem>
      <GridItem colSpan={{sm:1, xl:2}}>
        <Question/>
      </GridItem>
      <GridItem >
      <Grid gap={2} >   
        <Box justifySelf={{sm:"center", xl:"center"}}>
          <Logo />                
        </Box>     
        <Text justifySelf={{sm:"center", xl:"center"}}>© 2020 ib rehm </Text>        
        <Box justifySelf={{sm:"center", xl:"center"}}fontSize="small">          
          <chakra.a href="/impressum">IMPRESSUM</chakra.a> | 
          <chakra.a href="/datenschutz">DATENSCHUTZ</chakra.a>          
        </Box>
      </Grid>
      </GridItem>
    </Grid>
  )
}
export default Footer
