import React from "react";
import { Box, Flex, Image, Text, Heading, useColorModeValue } from "@chakra-ui/react";
const Card = ({property}) => {  

  return (
    <Flex my={2} justifyContent="center">
      <Box bg={useColorModeValue("gray.100", "gray.800")} maxW="md" borderWidth={"1px"} borderColor={"gray.200"} rounded="lg" shadow="lg">            
        <CardImage image={property.frontmatter.image} alt={property.frontmatter.title} />
        <CardHeader headline={property.frontmatter.title}/>    
        <CardBody  text={property.html}/>        
      </Box>
    </Flex>
  );
};


const CardHeader=({headline})=>{
  return(
    <Box  justifyContent="center" >                        
        <Heading as="h4" color="blue.700" fontWeight="bold" letterSpacing="widest" fontSize="2xl" textTransform="uppercase" ml={2} py={4}>          
          {headline}
        </Heading>
    </Box>
  )
}


const CardImage=({image,alt})=>{
  return(
    <Box >
        <Image rounded="lg" src={image} alt={alt}/>     
    </Box>   
  )
}
const CardBody=({text})=>{
  return(
    <Box id="card-body" >
        <Text mt="1" p={3} dangerouslySetInnerHTML={{ __html: text }}/>                      
    </Box>
  )
}


export default Card;
