import React from "react";
import {chakra,Box,useColorModeValue,Flex,IconButton,Button,Stack,} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons"
import Languages from "../ui/languages"
import Logo from "../ui/logo"
import { AiOutlineMenu } from "react-icons/ai";

const KuttyHero = () => {
  const bg = useColorModeValue("white", "gray.800");

  return (
    <chakra.header >
      <chakra.nav bg={bg} shadow="base">
        <Box mx="auto" px={6} py={3} maxW="full">
          <Box display={{ md: "flex" }} alignItems={{ md: "center" }} justifyContent={{ md: "space-between" }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box fontSize="xl" fontWeight="semibold" color="gray.700">                
                  <Logo/>               
              </Box>              
              <Box display={["none", , "flex"]} alignItems={{ md: "center" }}>
                <Languages/>
              </Box>
              <Flex display={{ md: "none" }}>
                <IconButton aria-label="toggle menu" icon={<AiOutlineMenu />} variant="ghost"/>
              </Flex>
            </Flex>           
          </Box>
        </Box>
      </chakra.nav>

      <Box mt={"-280px"} w="full" h="container.sm" backgroundImage="url('/assets/images/PS Lab IBR.jpg')" bgPos="center" bgSize="cover" minHeight= "100vh">
        <Flex align="center" pos="relative" justify="center" boxSize="full" bg="blackAlpha.700">
          <Claim/>
        </Flex>
      </Box>
    </chakra.header>
  );
};

const Claim=()=>{
  return(
          <Stack textAlign="center" alignItems="center" spacing={6} >
            <chakra.h1 mt={8} mb={2} leading={5} color="blue.400" fontSize="7xl">
              Ingenieurbüro Rehm
            </chakra.h1>
            <chakra.h2 mt={8} mb={2} leading={5} color="gray.400" fontSize="4xl">
              Ihr kompetenter und unabhängiger Leistungselektronik-Experte
            </chakra.h2>
            <chakra.h3 mt={8} mb={2} leading={5} color="gray.400" fontSize="2xl">
              IT, Industrie, Consumer, Medizin, Licht, Automotive & Bahn
            </chakra.h3>
            <chakra.p  color="gray.200" fontSize="2xl">Erfolgreich seit 1998</chakra.p>
            <chakra.a  href="#features">              
              <IconButton  bgColor="transparent" icon={<ChevronDownIcon w={16} h={16} />}/>                         
            </chakra.a>
          </Stack>
  )
}

export default KuttyHero;
