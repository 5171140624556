import React from "react";
import { Link } from "gatsby";
import {Grid, GridItem} from "@chakra-ui/react"
export default function Languages(){
   const languages =[
    {
      id: 1,
      name: "deutsch",
      short: "de",
      langSlug: "/",
      icon: "DE.png",
    },
    {
      id: 2,
      name: "english",
      short: "en",
      langSlug: "/index_en",
      icon: "EN.png"
    },
   {
     id: 3,
     name: "spanish",
     short: "es",
     langSlug: "/index_es",
     icon: "ES.png"
   },
   {
     id: 4,
     name: "chinese",
     short: "zh",
     langSlug: "/index_zh",
     icon: "ZH.png"
   }
  ]

  return(
    
    <Grid as="ul" textDecoration="none" gap={6} templateColumns="repeat(4, 1fr)">
      
      {languages.map(lang => (        
        <GridItem as="li"  display="inline-block" color="rgb(19, 120, 185)" textTransform="uppercase"  key={lang.id} >
          <Link  to={lang.langSlug}>
            {lang.short}
          </Link>
        </GridItem>        
      ))
      }
      
    </Grid>
    
  )
}
