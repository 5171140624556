import React from "react"
import {chakra,Box} from "@chakra-ui/react"
//import Header from "../sections/header"
import KuttyHero from "../sections/kuttyhero"
import Footer from "../sections/footer"

const Layout = ({children}) => {  

  return (
    
    <Box >
      <chakra.header>                
        <KuttyHero/>
      </chakra.header>      
      <chakra.section id="features">
        {children}        
      </chakra.section>
      <Footer/> 
    </Box>    
  )
}

export default Layout