import React from "react";
import {chakra,Box,Grid, Text} from "@chakra-ui/react"

function Contact () {
  return(
    <Grid rowGap={4}   textTransform="uppercase">
      <Box>
        <chakra.h4 color="blue.500" fontSize="2xl" fontWeight="bold">IBR Ingenieurbüro Rehm </chakra.h4>
        <chakra.h5 color="blue.400" fontSize="xl">LABOR FÜR LEISTUNGSELEKTRONIK </chakra.h5>
      </Box>
      <Box>
        
          <Text>Geschäftsführer: Prof. Markus Rehm</Text>
          <Text>Honorarprofessor Hochschule Furtwangen</Text>
          <Text>Dipl. Ing. (FH) & Master of E-Business (GA)</Text>
        
      </Box>
      <Box >
        
          <Text>Tiroler Straße 10</Text>
          <Text>D-78052 Villingen-Schwenningen</Text>
          <Text>Tel: +49 7721 737 17</Text>
          <Text>Fax: +49 7721 408 926</Text>
          <Text>E-mail: hello@ib-rehm.de</Text>
          <Text>Ust-IdNr: DE197253673</Text>
        
      </Box>
    </Grid>

  )
}


export default Contact
