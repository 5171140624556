import React from "react";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Stack,
  Link,
  Text,
} from "@chakra-ui/react";


{/* <Box>
              <chakra.h5>Downloads</chakra.h5>
              <chakra.a href=""></chakra.a>
          </Box> */}

function Question () {
return (
  <Flex h="100%" bg="transparent" w="full" alignItems="center" justifyContent="center">      
    <Box h="100%" bg={useColorModeValue("transparent", "transparent")} maxW="7xl" w={{ md: "xl", lg: "2xl" }} mx="auto" py={{ base: 4, lg: 8 }} px={{ base: 4, lg: 8 }} display={{ lg: "flex" }} alignItems={{ lg: "start" }} justifyContent={{ lg: "space-between" }} flexDirection="column">
      <Box fontSize={{ base: "xl", sm: "xl" }} letterSpacing="wide" lineHeight="shorter" color={useColorModeValue("gray.100", "gray.100")}>
        <Text display="block">Sie haben noch Fragen? Melden Sie sich bei uns.</Text>
        <Text>Gerne machen wir Ihnen ein passendes Angebot.</Text>
        <Text>Wir unterstützen Sie weltweit!</Text>        
      </Box>
                  
      <Box justifySelf="end" display="inline-flex" rounded="md" shadow="md">
        <Link
          href="/files/IBR2020.pdf"
          w="full"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          color={useColorModeValue("gray.100")}
          bg={useColorModeValue("brand.600", "brand.500")}
          _hover={{
            bg: useColorModeValue("brand.700", "brand.600"),
          }}
        >
          Download IBR presentation
        </Link>
      </Box>              
      
    </Box>
      
    </Flex>
  
  )

}
export default Question

