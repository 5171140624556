import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layouts/layout";
import {Grid} from "@chakra-ui/react"
import Card from "../components/ui/card";

const IndexPage = ({data}) => {
  
   return (
    <Layout >
        <Grid templateColumns={{sm:"1fr", md: "1fr 1fr",  xl: "1fr 1fr 1fr 1fr"}} gap={2}>      
          {data.content.nodes.map(card => (                    
              <Card property={card}/>                           
          ))
          }      
        </Grid>
    </Layout>  
  )
}

export default IndexPage

export const query = graphql`
    {
        content: allMarkdownRemark {
            nodes {
                frontmatter {                    
                    title
                    image
                }
                html
                rawMarkdownBody
            }
        }        
    }
`